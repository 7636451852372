// import * as styles from "./AboutWithGallery.module.css";

import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

export default function Team1({ title, description, members }) {
	return (
		<div className="team-1">
			<Container>
				<Row>
					<Col className="ml-auto mr-auto text-center" md="8">
						<h2 className="title">{title}</h2>
						<h4 className="description">{description}</h4>
					</Col>
				</Row>
				<Row className="justify-content-center">
					{members.map((member, i) => (
						<Member key={i} {...member} />
					))}
				</Row>
			</Container>
		</div>
	);
}

function Member({ image, name, title, description }) {
	return (
		<Col className="ml-1" md="4">
			<Card className="card-profile card-plain">
				{image ? (
					<div className="card-avatar">
						<img alt="..." className="img img-raised" src={image} />
					</div>
				) : null}
				<CardBody>
					<CardTitle tag="h3">{name}</CardTitle>
					{title ? (
						<h6 className="category text-info">{title}</h6>
					) : null}
					{description ? (
						<p className="card-description">{description}</p>
					) : null}
				</CardBody>
			</Card>
		</Col>
	);
}
