import React from "react";
import { Card, Container, Row, Col } from "reactstrap";
import MarkdownView from "react-showdown";

export default function Features8({
	title,
	description,
	features,
	backgroundImage,
}) {
	return (
		<div
			className="features-8 section-image"
			style={{
				backgroundImage: `url(${backgroundImage})`,
			}}
		>
			<Col className="ml-auto mr-auto text-center" md="8">
				<h2 className="title">{title}</h2>
				<h4 className="description">
					<MarkdownView markdown={description} />
				</h4>
			</Col>
			<Container>
				<Row>
					{features.map((feature, i) => (
						<Feature
							key={i}
							numFeatures={features.length}
							{...feature}
						/>
					))}
				</Row>
			</Container>
		</div>
	);
}

function Feature({ numFeatures, image, title, icon, text }) {
	return (
		<Col md={12 / numFeatures}>
			<Card>
				<div className="card-image">
					<img alt="" className="rounded" src={image} />
				</div>
				<div className="info text-center">
					{icon ? (
						<div className="icon">
							<i className={`now-ui-icons ${icon}`} />
						</div>
					) : null}
					<h4 className="info-title">{title}</h4>
					<p className="description">{text}</p>
				</div>
			</Card>
		</Col>
	);
}
