import React from "react";
import { Card, Container, Row, Col } from "reactstrap";
import MarkdownView from "react-showdown";

export default function Features4({ title, description, features }) {
	return (
		<div className="features-4">
			<Container>
				<Row>
					<Col className="ml-auto mr-auto text-center" md="8">
						<h2 className="title">{title}</h2>
						<h4 className="description">{description}</h4>
					</Col>
				</Row>
				<Row>
					{features.map((feature, i) => (
						<Feature
							key={i}
							numFeatures={features.length}
							{...feature}
						/>
					))}
				</Row>
			</Container>
		</div>
	);
}

function Feature({ numFeatures, backgroundImage, title, icon, text }) {
	return (
		<Col md={12 / numFeatures}>
			<Card
				className="card-background card-raised"
				data-background-color=""
				style={{
					backgroundImage: `url(${backgroundImage})`,
				}}
			>
				<div className="info">
					<div className="icon icon-white">
						<i className={`now-ui-icons ${icon}`} />
					</div>
					<div className="description">
						<h4 className="info-title">{title}</h4>
						<MarkdownView markdown={text} />
						{/* <a */}
						{/* 	className="ml-3" */}
						{/* 	href="#pablo" */}
						{/* 	onClick={(e) => e.preventDefault()} */}
						{/* > */}
						{/* 	Find more... */}
						{/* </a> */}
					</div>
				</div>
			</Card>
		</Col>
	);
}
