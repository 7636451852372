import config from "../config.json";
import Features4 from "./composed/Features4";
import Features8 from "./composed/Features8";
import AboutWithGallery from "./composed/AboutWithGallery";
import Team1 from "./composed/Team1";
import { Separator1 } from "./composed/Separators";
import AboutUsHeader from "./uikit/Headers/AboutUsHeader";

import "./uikit/css/bootstrap.min.css";
import "./uikit/css/now-ui-kit.css";

import React from "react";
import { Helmet } from "react-helmet";

const SECTION_COMPONENTS = {
    AboutUsHeader,
    AboutWithGallery,
    Features4,
    Features8,
    Separator1,
    Team1,
};

export default function Page({ pageContext: { page } }) {
    const { title, sections } = page;

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                {config.site.description ? (
                    <meta
                        name="description"
                        content={config.site.description}
                    />
                ) : null}
                {config.site.favicon ? (
                    <link rel="icon" href={config.site.favicon} />
                ) : null}
            </Helmet>
            {(sections || []).map((section, i) => (
                <Section key={i} section={section} />
            ))}
        </div>
    );
}

function Section({ section }) {
    const { type, props } = section;
    const Comp = SECTION_COMPONENTS[type];
    return <Comp {...props} />;
}
