import * as styles from "./AboutWithGallery.module.css";

import React from "react";
import { Container, Row, Col } from "reactstrap";
import MarkdownView from "react-showdown";

export default function AboutWithGallery({ title, description, images }) {
	return (
		<div className={styles.aboutWithGallery}>
			<Container>
				<Row className="text-center">
					<Col className="ml-auto mr-auto" md="8">
						<h2 className="title">{title}</h2>
						<h4 className="description">
							<MarkdownView markdown={description} />
						</h4>
					</Col>
				</Row>
				<Row>
					{(images || []).map(({ src, alt, gridCols }) => (
						<Col md={gridCols} key={src}>
							<img
								alt={alt || ""}
								className="rounded img-raised"
								src={src}
							/>
						</Col>
					))}
				</Row>
			</Container>
		</div>
	);
}
